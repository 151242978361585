import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminGuard } from '@app/core/auth/admin.guard';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { ClientGuard } from '@app/core/auth/client.guard';
import { GuideGuard } from '@app/core/auth/guide.guard';
import { SoloWorkspaceGuard } from '@app/core/auth/solo-workspace.guard';
import { SuperAdminGuard } from '@app/core/auth/super-admin.guard';
import { TeamWorkspaceAdminGuard } from '@app/core/auth/team-workspace-admin.guard';
import { WorkspaceAdminGuard } from '@app/core/auth/workspace-admin.guard';
import { WorkspaceGuard } from '@app/core/auth/workspace.guard';
import { MembershipGuard } from '@app/core/membership/membership.guard';
import { SubscriptionPageGuard } from '@app/core/membership/subscription-page.guard';
import { AreGuidePackagesAccessibleGuard } from '@app/core/public/are-guide-packages-accessible.guard';
import { LandingGuard } from '@app/core/public/landing.guard';
import { PublicEventsGuard } from '@app/core/public/public-events.guard';
import { PublicProgramsGuard } from '@app/core/public/public-programs.guard';
import { CanCreateServicesGuard } from '@app/core/public/can-create-services.guard';
import { AltPrimaryLayoutComponent } from '@app/layouts/alt-primary-layout/alt-primary-layout.component';
import { AuthGuideLayoutComponent } from '@app/layouts/auth-guide-layout/auth-guide-layout.component';
import { AuthLayoutComponent } from '@app/layouts/auth-layout/auth-layout.component';
import { CommunityLayoutComponent } from '@app/layouts/community-layout/community-layout.component';
import { EmptyLayoutComponent } from '@app/layouts/empty-layout/empty-layout.component';
import { GiftCouponsLayoutComponent } from '@app/layouts/gift-coupons-layout/gift-coupons-layout.component';
import { LandingBusinessLayoutComponent } from '@app/layouts/landing-business-layout/landing-business-layout.component';
import { LandingLayoutComponent } from '@app/layouts/landing-layout/landing-layout.component';
import { MainLayoutComponent } from '@app/layouts/main-layout/main-layout.component';
import { MarketplaceLayoutComponent } from '@app/layouts/marketplace-layout/marketplace-layout.component';
import { MembershipLayoutComponent } from '@app/layouts/membership-layout/membership-layout.component';
import { PrimaryLayoutComponent } from '@app/layouts/primary-layout/primary-layout.component';
import { SimpleLayoutComponent } from '@app/layouts/simple-layout/simple-layout.component';
import { GiftCouponActivationGuard } from '@app/modules/coupon-activation/gift-coupon-activation.guard';
import { NotFoundComponent } from '@app/modules/not-found/not-found.component';
import { TokenSignInComponent } from '@app/modules/token-sign-in/token-sign-in.component';
import { routes as mainRoutes } from '@app/routes';
import * as lazyLoader from '@app/screens/admin/modules/loader';
import { routes as authGuideRoutes } from '@app/screens/auth/auth-guide-routes';
import { routes as authRoutes } from '@app/screens/auth/auth-routes';
import { routes as forgotPasswordRoutes } from '@app/screens/auth/forgot-password-routes';
import { blogRoutes, businessBlogRoutes } from '@app/screens/blog/blog-routing.module';
import { routes as findYourGuideRoutes } from '@app/screens/find-your-guide/find-your-guide-routing.module';
import { routes as giftCouponsRoutes } from '@app/screens/gift-coupons/gift-coupons-routing.module';
import { packagesRoutes } from '@app/screens/packages/routes';
import { programRoutes, programsRoutes } from '@app/screens/programs/routes';
import { sessionTemplatesRoutes } from '@app/screens/session-templates/routes';
import { routes as surveyRoutes } from '@app/screens/surveys/routes';
import { TeamCreateActionResolver } from '@app/screens/team-member-action/resolvers/team-create-action.resolver';
import { TeamMemberActionResolver } from '@app/screens/team-member-action/resolvers/team-member-action.resolver';
import { routes as wikiRoutes } from '@app/screens/wiki/wiki-routing.module';
import { environment } from '@env/environment';

import { ClientInvitationActivationComponent } from './modules/client-invitation/client-invitation-activation.component';
import { ClientInvitationActivationGuard } from './modules/client-invitation/client-invitation-activation.guard';
import { GiftCouponActivationComponent } from './modules/coupon-activation/gift-coupon-activation.component';
import { EmailVerificationComponent } from './modules/email-verification/email-verification.component';
import { ADMIN_ROUTE_PATH } from './routes-consts';
import { ExternalLinkComponent } from '@app/modules/external-link/external-link.component';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: environment.membershipRoute, // path: 'subscription'
          component: MembershipLayoutComponent,
          loadChildren: () =>
            import('@app/screens/membership/membership.module').then(membership => membership.MembershipModule),
          canActivate: [SubscriptionPageGuard]
        },
        {
          path: '',
          children: [
            {
              path: 'auth',
              children: [
                {
                  path: environment.guideRoute,
                  component: AuthGuideLayoutComponent,
                  children: authGuideRoutes
                },
                {
                  path: 'forgot-password',
                  component: AuthLayoutComponent,
                  children: forgotPasswordRoutes
                },
                { path: '', component: AuthLayoutComponent, children: authRoutes }
              ]
            },
            {
              path: 'gift-coupons',
              component: GiftCouponsLayoutComponent,
              children: giftCouponsRoutes,
              runGuardsAndResolvers: 'always'
            },
            {
              path: 'surveys',
              component: PrimaryLayoutComponent,
              canActivate: [AuthGuard, ClientGuard],
              canLoad: [AuthGuard, ClientGuard],
              children: surveyRoutes,
              runGuardsAndResolvers: 'always'
            },
            {
              path: 'email-verification/:hash',
              component: EmailVerificationComponent
            },
            {
              path: 'sign-in-by-token/:token',
              component: TokenSignInComponent
            },
            {
              path: 'user/event-actions',
              component: SimpleLayoutComponent,
              canActivate: [AuthGuard],
              canLoad: [AuthGuard],
              // eslint-disable-next-line id-length
              loadChildren: () => import('@app/screens/event-action/event-action.module').then(m => m.EventActionModule)
            },
            {
              path: 'team-invitation/:hash',
              component: SimpleLayoutComponent,
              resolve: { teamMember: TeamMemberActionResolver }
            },
            {
              path: 'team-create',
              component: SimpleLayoutComponent,
              resolve: { teamCreate: TeamCreateActionResolver }
            },
            {
              path: 'gift-coupons/activation/:hash',
              canActivate: [GiftCouponActivationGuard],
              component: GiftCouponActivationComponent
            },
            {
              path: 'invitations/activation/:hash',
              canActivate: [ClientInvitationActivationGuard],
              component: ClientInvitationActivationComponent
            },
            {
              path: 'blog',
              component: LandingLayoutComponent,
              children: blogRoutes
            },
            {
              path: 'business-blog',
              component: LandingBusinessLayoutComponent,
              children: businessBlogRoutes
            },
            {
              path: 'wiki',
              component: PrimaryLayoutComponent,
              children: wikiRoutes
            },
            {
              path: 'programs',
              component: PrimaryLayoutComponent,
              children: programsRoutes
            },
            {
              path: 'programs',
              component: EmptyLayoutComponent,
              children: programRoutes
            },
            {
              path: 'packages',
              component: EmptyLayoutComponent,
              canActivate: [AreGuidePackagesAccessibleGuard],
              canLoad: [AreGuidePackagesAccessibleGuard],
              children: packagesRoutes
            },
            {
              path: 'sessions',
              component: EmptyLayoutComponent,
              children: sessionTemplatesRoutes
            },
            {
              path: `${environment.guideRoute}/surveys`,
              component: EmptyLayoutComponent,
              canActivate: [AuthGuard, GuideGuard],
              canLoad: [AuthGuard, GuideGuard],
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                // eslint-disable-next-line id-length
                import('@app/screens/guide/guide-surveys/guide-surveys.module').then(m => m.GuideSurveysModule)
            },
            {
              path: `${environment.guideRoute}/programs`,
              component: EmptyLayoutComponent,
              canActivate: [AuthGuard, GuideGuard],
              canLoad: [AuthGuard, GuideGuard],
              loadChildren: () =>
                // eslint-disable-next-line id-length
                import('@app/screens/guide/guide-programs/guide-programs.module').then(m => m.GuideProgramsModule)
            },
            {
              path: `${environment.guideRoute}/services/new`,
              component: EmptyLayoutComponent,
              canActivate: [AuthGuard, GuideGuard],
              loadChildren: () =>
                import('@app/screens/guide/guide-service-selector/guide-service-selector.module').then(
                  // eslint-disable-next-line id-length
                  m => m.GuideServiceSelectorModule
                )
            },
            {
              path: `${environment.guideRoute}/services/sessions`,
              component: EmptyLayoutComponent,
              canActivate: [AuthGuard, GuideGuard],
              canLoad: [AuthGuard, GuideGuard],
              loadChildren: () =>
                import('@app/screens/guide/guide-sessions-templates/guide-sessions-templates.module').then(
                  // eslint-disable-next-line id-length
                  m => m.GuideSessionsTemplatesModule
                )
            },
            {
              path: `${environment.guideRoute}/services/packages`,
              component: EmptyLayoutComponent,
              canActivate: [AuthGuard, GuideGuard, AreGuidePackagesAccessibleGuard],
              canLoad: [AuthGuard, GuideGuard, AreGuidePackagesAccessibleGuard],
              loadChildren: () =>
                // eslint-disable-next-line id-length
                import('@app/screens/guide/guide-packages/guide-packages.module').then(m => m.GuidePackagesModule)
            },
            {
              path: 'client/programs',
              canActivate: [AuthGuard, ClientGuard],
              canLoad: [AuthGuard, ClientGuard],
              loadChildren: () =>
                // eslint-disable-next-line id-length
                import('@app/screens/client/client-programs/client-programs.module').then(m => m.ClientProgramsModule)
            },
            {
              path: 'our-community',
              component: CommunityLayoutComponent,
              loadChildren: () =>
                // eslint-disable-next-line id-length
                import('@app/screens/our-community/our-community.module').then(m => m.OurCommunityModule)
            },
            {
              path: 'marketplace',
              component: MarketplaceLayoutComponent,
              // eslint-disable-next-line id-length
              loadChildren: () => import('@app/screens/marketplace/marketplace.module').then(m => m.MarketplaceModule)
            },
            {
              path: 'page/:path',
              component: CommunityLayoutComponent,
              // eslint-disable-next-line id-length
              loadChildren: () => import('@app/screens/public-pages/public-pages.module').then(m => m.PublicPagesModule)
            },
            {
              path: 'download',
              component: EmptyLayoutComponent,
              // eslint-disable-next-line id-length
              loadChildren: () => import('@app/screens/download/download.module').then(m => m.DownloadModule)
            },
            {
              path: 'meeting',
              loadChildren: () => import('@app/screens/meeting/meeting.module').then(module => module.MeetingModule)
            },
            {
              path: 'not-found',
              component: NotFoundComponent
            },
            {
              path: 'ext',
              redirectTo: ''
            },
            {
              path: environment.guidesRoute,
              component: MainLayoutComponent,
              // DO NOT USE LAZY loading for this module
              // eslint-disable-next-line max-len
              // loadChildren: () => import('@app/screens/find-your-guide/find-your-guide.module').then(m => m.FindYourGuideModule)
              children: [{ path: '', children: findYourGuideRoutes }]
            },
            {
              path: 'admin/default-service',
              loadChildren: lazyLoader.loadDefaultServiceEditor,
              canActivate: [AuthGuard, AdminGuard],
              canLoad: [AuthGuard, AdminGuard],
              runGuardsAndResolvers: 'always'
            },
            {
              path: 'admin/default-form',
              loadChildren: lazyLoader.loadDefaultFormEditor,
              canActivate: [AuthGuard, AdminGuard],
              canLoad: [AuthGuard, AdminGuard],
              runGuardsAndResolvers: 'always'
            },
            {
              path: ADMIN_ROUTE_PATH,
              // eslint-disable-next-line id-length
              loadChildren: () => import('@app/screens/admin/admin.module').then(m => m.AdminModule),
              component: AltPrimaryLayoutComponent,
              canActivate: [AuthGuard, AdminGuard],
              canLoad: [AuthGuard, AdminGuard],
              runGuardsAndResolvers: 'always'
            },
            {
              path: 'external-link',
              component: ExternalLinkComponent
            },
            {
              path: '',
              component: PrimaryLayoutComponent,
              children: mainRoutes
            }
          ]
          // canActivate: [MembershipGuard]
        }
      ],
      {
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        // enableTracing: true,
        bindToComponentInputs: true
        // initialNavigation: 'enabled'
      }
    )
  ],
  providers: [
    AuthGuard,
    GuideGuard,
    ClientGuard,
    AdminGuard,
    LandingGuard,
    MembershipGuard,
    SubscriptionPageGuard,
    PublicEventsGuard,
    PublicProgramsGuard,
    AreGuidePackagesAccessibleGuard,
    SuperAdminGuard,
    TeamWorkspaceAdminGuard,
    WorkspaceAdminGuard,
    TeamMemberActionResolver,
    TeamCreateActionResolver,
    SoloWorkspaceGuard,
    WorkspaceGuard,
    CanCreateServicesGuard
  ]
})
export class AppRoutingModule {}
